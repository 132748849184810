import { helpStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";

import HelpDateComponent from "../components/HelpDateComponent";
import HelpContainerComponent from "../components/HelpContainerComponent";
import SkipHelpComponent from "../components/SkipHelpComponent";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { addBooking, getAllBookableTimes, getCompanyName } from "../apiHelper";
import { useLocation, useNavigate } from "react-router-dom";

function Help() {
  const classes = helpStyles();
  const location = useLocation();
  const { user, getAccessTokenSilently } = useAuth0();
  const [bookingData, changeBookingData] = useState({
    comments: "",
    anonymous: false,
  });
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [companyName, setCompanyName] = useState();
  const [availableData, changeAvailableData] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_TYPE && !location.state.score) {
      history("/");
    }
    if (user && user.sub) {
      getCompanyName({ externalId: user.sub }).then((s) => {
        setCompanyName(s.data.accountName);
      });
    }
    getAccessTokenSilently().then((t) => {
      getAllBookableTimes(t, { externalId: user.sub }).then((a) => {
        changeAvailableData(a);
      });
    });
  }, [user]);

  const handleSubmit = () => {
    getAccessTokenSilently().then((t) => {
      addBooking(t, {
        ...bookingData,
        helpProviderPersonId: user.sub,
        score: location.state?.score || 0,
        questionId: location.state?.questionId || null,
        somethingElse: location.state?.somethingElse || true,
        chatProviderUserId: availableData[selectedUserIndex].providerId,
      })
        .catch((e) => {
          console.log(e);
        })
        .finally(history("/success"));
    });
  };
  return (
    <Box className={classes.backdrop}>
      <Box className={classes.helpPageOuter}>
        <Box className={classes.helpPageTop}>
          <Typography variant="h1">
            {companyName} is sorry to hear you are feeling down, we are here for
            you...
          </Typography>
        </Box>
        <Box className={classes.helpPageBookingContainer}>
          <Box className={classes.helpPageInner}>
            <Box className={classes.helpPageInnerLeft}>
              {availableData.length > 0 && (
                <HelpDateComponent
                  availableData={availableData[selectedUserIndex]}
                  bookingData={bookingData}
                  changeBookingData={changeBookingData}
                  selectedUserIndex={selectedUserIndex}
                  setSelectedUserIndex={setSelectedUserIndex}
                  maxIndex={availableData.length}
                />
              )}
            </Box>
            <Box className={classes.helpPageInnerRight}>
              <HelpContainerComponent
                handleSubmit={handleSubmit}
                bookingData={bookingData}
                changeBookingData={changeBookingData}
              />
            </Box>
          </Box>
          <SkipHelpComponent />
        </Box>
      </Box>
    </Box>
  );
}

export default Help;
