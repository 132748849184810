import { hrOverviewStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import NotificationComponent from "../hrDashboardComponents/NotificationComponent";
import * as isoWeek from "dayjs/plugin/isoWeek";
import dayjs from "dayjs";
import { DateRangeSelector } from "../hrDashboardComponents/DateRangeSelector";
import SummaryPie from "../hrDashboardComponents/dashboardComponents/SummaryPie";
import SummaryLine from "../hrDashboardComponents/dashboardComponents/SummaryLine";
import SummaryBar from "../hrDashboardComponents/dashboardComponents/SummaryBar";
import ResponsesLog from "../hrDashboardComponents/dashboardComponents/ResponsesLog";
import SummaryBarGroup from "../hrDashboardComponents/dashboardComponents/SummaryBarGroup";
import SummaryBarVertical from "../hrDashboardComponents/dashboardComponents/SummaryBarVertical";
dayjs.extend(isoWeek);

function Dashboard() {
  const classes = hrOverviewStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [range, setRange] = useState(new Date());

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.hrOverviewContainer}>
      <Box className={classes.headingRow}>
        <Typography variant="h1">Dashboard</Typography>
        <Box className={classes.hrBarRight}>
          <Box className={classes.arrowContainer}>
            {/* <NotificationsIcon onClick={handleClick} color="secondary" /> */}
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <NotificationComponent handleClose={handleClose} />
            </Menu>
          </Box>
          <DateRangeSelector range={range} setRange={setRange} />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.hrRowOuter}>
          <Box className={classes.hrRow}>
            <SummaryPie
              value={55}
              title="Overall Company Score"
              subtitle="Looking a bit low!"
            />
            <SummaryPie
              value={55}
              title="Participation Today"
              subtitle="Looking a bit low!"
            />
            <SummaryPie
              value={55}
              title="Today's Score"
              subtitle="Looking a bit low!"
            />
          </Box>
        </Box>
        <Box className={classes.hrRowOuter}>
          <Box className={classes.hrRow}>
            <SummaryLine type="grow" title="Change since last week" />
            <SummaryLine title="Participation Trends" />
          </Box>
        </Box>
        <Box className={classes.hrRowOuter}>
          <Box className={classes.thirdRowLeft}>
            <SummaryBarGroup groupTitle={"People using the Hotline"} />
            <SummaryBarGroup groupTitle={"Amount of bookings to HR"} />
          </Box>
          <Box className={classes.thirdRowRight}>
            <ResponsesLog />
          </Box>
        </Box>
        {/* <Box className={classes.hrRowOuter}></Box> */}
        {/* <SummaryBarVertical /> */}
      </Box>
    </Box>
  );
}

export default Dashboard;
